/* ===================
General 
=================== */ 
.App {
    background-image: url("./assets/GerechtsgebouwGent.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    height: 100%;
    width: 100%;
}

.alert {
    margin-bottom: 0%;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active {
    background-color:  rgb(33, 44, 107);
    border-color: rgb(33, 44, 107);
}

.btn-outline-secondary {
    border: none;
    color: white;
    background-color: rgba(33, 44, 107, 0.6);
}

@media only screen and (max-width: 576px) {
    .App {
        background: none;
        height: 100%;
        width: 100%;
    }

    .mobilebg {
        background-image: url("./assets/GerechtsgebouwGent.jpg");
        background-repeat: no-repeat;
        background-attachment: local;
        background-size: cover;
    }
    
    .btn-toolbar {
        justify-content: center;
    }

    .shortAddressCol {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .shortAddress {
        width: 90%;
    }
    
    .contactButton {
        display: block;
        margin: 0.1rem 0;
    }

    header {
        padding: 0;
    }

    div.expertiseIconCol.col {
        height: 6rem;
        min-height: 2rem;
        font-size: 5px;
    }
    
    div.expertiseIconCol.col-sm-6.col-12 {
        height: 6rem;
        min-height: 2rem;
        font-size: 5px;    
    }

    div.expertiseIconCol.overzicht.col {
        display: none;
    }

}

/* ===================
Header 
=================== */ 

header {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 1.5rem 0;
}

.logoBlauw {
    max-width: 40rem;
    margin: 1.5rem auto;
}

.shortAddressCol {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}


.shortAddress {
    min-width: 50%;
}
/* ===================
Contact 
=================== */ 

.contact {
    color: white;
    background-color: rgba(33, 44, 107, 0.3);
    padding-bottom: 3rem;
    text-align: center;
}

h1 {
    text-align: center;
    margin: 2rem 0 3rem;
}

.contactButton {
    font-size: 1.5rem;
}

/* ===================
Specialisaties 
=================== */ 

.specialisaties {
    background-color: white;
    padding-bottom: 2rem;
    text-align: center;
}

.specialisatieCol {
    padding-bottom: 2rem;
}

/* ===================
KantoorPage
====================*/
.rule {
    background: rgba(33, 44, 107, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}
hr {
    border:0;
    margin:0;
    width:60%;
    height:2px;
    background:#ffb067;
}
.kantoorCol {
    background-color: white;
    padding-top: 0.5rem;
    padding-top: 1rem;
    display: flex;
    align-items: center;
    min-height: 30rem;
}

.kantoorMainCol h1 {
    margin-bottom: 1rem;
}
.kantoorMainCol h4 {
    margin-top: 1rem;
}

.kantoorMainCol {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(33, 44, 107, 0.6);
    color: white;
    padding: 1rem;
    min-height: 30rem;
}

.kaartSwitch {
    position: absolute;
    bottom: 1%;
    right: 1%;
}

.kaartje {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    max-height: 25rem;
}

.map {
    width: 100%;
    height: 100%;
}

/* ===================
ExpertisePage
====================*/

.expertiseCol {
    background-color: white;
    padding-top: 0.5rem;
    min-height: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.expertiseIconCol {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(33, 44, 107, 0.6);
    color: #ffb067;
    min-height: 30rem;
}

.rechtsbijstand {
    background-color: rgba(33, 44, 107, 0.6);
    color: white;
    text-align: center;
    padding: 2rem 0 2rem;
}

.expertisepage h2, h4{
    text-align: center;
}

/* ===================
Contact 
=================== */ 

.invul {
    visibility: hidden;
    position: absolute;
}

/* ===================
Footer 
=================== */ 
footer {
    background-color: rgba(33, 44, 107, 0.6);
    color: white;
    padding: 1.5rem 0;
}

.footerButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1.5rem;
}

.logoCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logoWit {
    max-width: 20rem;
    align-self: flex-end;
    margin: 0 10% 5%;
}

.address {
    padding-left: 10%;
}



